import React from "react"
import { Helmet } from "react-helmet";
import BrandLogo from "../../../images/logo.png";
import { useLocation } from "@reach/router";
const OfficeSchema = ({ officeData }) => {
  const { title, description, ratingValue, reviewCount } = officeData;
  const location = useLocation();
  let schema;

  const ChelmsfordSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Property Estate Agents in Chelmsford",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "CM1 1EH",
      "streetAddress": "2 Tindal Square",
      "addressRegion": "Chelmsford",
      "addressLocality": "Essex"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-chelmsford-branch-58575f2ac3.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": 399,
      "bestRating": "5"
    },
    "telephone": "01245 492424",
    "url": "https://www.balgoresproperty.co.uk/branches/estate-agents-in-chelmsford/",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://www.google.com/maps?sca_esv=c744cb070de47b7e&sxsrf=ADLYWIIhskPAlzpLR6u2LLG4WOlNa9zJlA:1732530812776&uact=5&gs_lp=Egxnd3Mtd2l6LXNlcnAiE2JhbGdvcmVzIGNoZWxtc2ZvcmQyChAjGIAEGCcYigUyEBAuGIAEGBQYhwIYxwEYrwEyChAAGIAEGBQYhwIyBRAAGIAEMgUQABiABDIFEAAYgAQyBhAAGBYYHjILEAAYgAQYhgMYigUyCBAAGIAEGKIEMggQABiABBiiBDIfEC4YgAQYFBiHAhjHARivARiXBRjcBBjeBBjgBNgBAUjpGFDpDVihGHAGeAGQAQGYAboBoAGbEaoBBDIuMTW4AQPIAQD4AQGYAhagAsYQwgIKEAAYsAMY1gQYR8ICDRAAGLADGNYEGEcYyQPCAg4QABiABBiwAxiSAxiKBcICBBAjGCfCAhMQLhiABBhDGMcBGIoFGI4FGK8BwgIQEC4YgAQYQxjHARiKBRivAcICChAAGIAEGEMYigXCAgsQABiABBiRAhiKBcICCxAuGIAEGMcBGK8BwgIWEC4YgAQYQxjHARiYBRiZBRiKBRivAcICDhAuGIAEGMcBGI4FGK8BwgINEAAYgAQYQxjJAxiKBcICERAuGIAEGJECGMcBGIoFGK8BmAMAiAYBkAYKugYGCAEQARgUkgcENi4xNqAHw6oC&um=1&ie=UTF-8&fb=1&gl=uk&sa=X&geocode=KY-5zITX69hHMU4-gSDxKtqg&daddr=2+Tindal+Square,+Chelmsford+CM1+1EH",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00"
    ]
  };

  const DagenhamSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in Dagenham",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "RM9 5AQ",
      "streetAddress": "293 Heathway",
      "addressRegion": "Essex",
      "addressLocality": "Dagenham",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-dagenham-branch-abeedb0897.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.6",
      "reviewCount": 301,
      "bestRating": "5"
    },
    "telephone": "020 8592 1038",
    "url": "https://www.balgoresdagenham.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/zEbmB4EQUN5tKiSz8",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "sales@balgoresdagenham.com",
    "priceRange": "$$$"
  };

  const GravesendSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in Gravesend",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "DA12 1ER",
      "streetAddress": "172-173 Parrock St",
      "addressRegion": "Kent",
      "addressLocality": "Gravesend",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-gravesend-branch-9738c58358.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": 350,
      "bestRating": "5"
    },
    "telephone": "01474 369 955",
    "url": "https://www.balgoresgravesend.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/RrxiBAH5qf6FS4PV9",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "sales@balgoresgravesend.com",
    "priceRange": "$$$"
  };

  const HornchurchSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in Hornchurch",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "RM11 1RL",
      "streetAddress": "1 North St",
      "addressRegion": "Essex",
      "addressLocality": "Hornchurch",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-hornchurch-branch-b28e37e4c3.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.6",
      "reviewCount": 342,
      "bestRating": "5"
    },
    "telephone": "01708 470 756",
    "url": "https://www.balgoreshornchurch.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/CZSCjBGXbYDWn35d7",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "sales@balgoresproperty.com",
    "priceRange": "$$$"
  };

  const LoughtonSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in Loughton",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "IG10 4HJ",
      "streetAddress": "110 High Rd",
      "addressRegion": "Essex",
      "addressLocality": "Loughton",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-loughton-branch-576cca6734.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": 399,
      "bestRating": "5"
    },
    "telephone": "020 8502 1559",
    "url": "https://www.balgoresloughton.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/RoJC2AxvU5mhYET5A",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "lettings@balgoresloughton.com",
    "priceRange": "$$$"
  };

  const OngarSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in Ongar",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "CM5 9JH",
      "streetAddress": "134 High St, Chipping Ongar",
      "addressRegion": "Essex",
      "addressLocality": "Ongar",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-ongar-branch-5bda5f475c.jpeg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.7",
      "reviewCount": 35,
      "bestRating": "5"
    },
    "telephone": "01277 369 925",
    "url": "https://www.balgoresongar.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/Jv5R6fnSn72hPJWU7",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "ongar@balgoreshayes.com",
    "priceRange": "$$$"
  };

  const RomfordSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in Romford",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "RM1 2HS",
      "streetAddress": "5-6, Station Chambers, Victoria Rd",
      "addressRegion": "Essex",
      "addressLocality": "Romford",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-romford-branch-6730399972.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": "399",
      "bestRating": "5"
    },
    "telephone": "01708 755 507",
    "url": "https://www.balgoresromford.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/NrVMZMVgCvcaSMGc7",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "sales@balgoresromford.com",
    "priceRange": "$$$"
  };

  const South_Woodham_FerrersSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in South Woodham Ferrers",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "CM3 5XQ",
      "streetAddress": "5-7 Baron Rd",
      "addressRegion": "Essex",
      "addressLocality": "South Woodham Ferrers",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-romford-branch-6730399972.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": 66,
      "bestRating": "5"
    },
    "telephone": "01245 323 729",
    "url": "https://www.balgoresswf.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/GGuFUYr9j5SnrqL28",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "sales@balgoresswf.com",
    "priceRange": "$$$"
  };

  const UpminsterSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in Upminster",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "RM14 2SU",
      "streetAddress": "63 Station Rd",
      "addressRegion": "Essex",
      "addressLocality": "Upminster",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-upminster-branch-f24b8224fd.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.9",
      "reviewCount": 364,
      "bestRating": "5"
    },
    "telephone": "01708 259 539",
    "url": "https://www.balgoresupminster.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/72jSnj4ke28ZT9kF6",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "sales@balgoresupminster.com",
    "priceRange": "$$$"
  };

  const WickfordSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Estate Agents in Wickford",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "SS12 9AZ",
      "streetAddress": "10 High St",
      "addressRegion": "Essex",
      "addressLocality": "Wickford",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/Wickfrod-2024-4a6f46087e.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.8",
      "reviewCount": 93,
      "bestRating": "5"
    },
    "telephone": "01268 763 477",
    "url": "https://www.balgoreswickford.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/ZeZQHZuZ23ocQri2A",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "sales@balgoreswickford.com",
    "priceRange": "$$$"
  };

  const BasildonSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Property Estate Agents in Basildon",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "SS14 1EL",
      "streetAddress": "28 Southernhay",
      "addressRegion": "Essex",
      "addressLocality": "Basildon",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-basildon-branch-80a66c588d.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.7",
      "reviewCount": 406,
      "bestRating": "5"
    },
    "telephone": "01268 271 110",
    "email": "sales@balgoresbasildon.com",
    "url": "https://www.balgoresproperty.co.uk/branches/estate-agents-in-basildon/",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/Uhj5bfY7Fos6rf4V9",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "priceRange": "$$$"
  };

  const BrentwoodSchema = {
    "@context": "https://schema.org/",
    "@type": "RealEstateAgent",
    "name": "Balgores Property Estate Agents in Brentwood",
    "address": {
      "@type": "PostalAddress",
      "postalCode": "CM14 4DB",
      "streetAddress": "7 St. Thomas' Road",
      "addressRegion": "Essex",
      "addressLocality": "Brentwood",
      "addressCountry": "UK"
    },
    "image": "https://ggfx-balgores.s3.eu-west-2.amazonaws.com/x.prod/764x600/estate-agents-in-brentwood-branch-ac6a05598a.jpg",
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "4.5",
      "reviewCount": 74,
      "bestRating": "5"
    },
    "telephone": "01277 216 800",
    "url": "https://www.balgoreshayes.com",
    "mainEntityOfPage": {
      "@type": "WebPage"
    },
    "hasMap": "https://maps.app.goo.gl/a4q96YQ8vLv8h54M6",
    "branchOf": {
      "@type": "Corporation"
    },
    "openingHours": [
      "Mo-Fr 09:00-18:00",
      "Sa 09:00-16:00",
      "Su Closed"
    ],
    "email": "sales@balgoreshayes.com",
    "priceRange": "$$$"
  };

  const LocalBusinessSchema = {
    "@context": "http://schema.org",
    "@type": "LocalBusiness",
    "name": "${title}",
    "description": "${description}",
    "brand": {
      "@type": "Brand",
      "name": "Balgores Property Group",
      "image": "${BrandLogo}"
    },
    "aggregateRating": {
      "@type": "AggregateRating",
      "ratingValue": "${ratingValue}",
      "reviewCount": "${reviewCount}",
      "bestRating": "5"
    }
  };

  if (location && location.pathname == "/branches/estate-agents-in-chelmsford/") {
    schema = JSON.stringify(ChelmsfordSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-wickford/") {
    schema = JSON.stringify(WickfordSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-upminster/") {
    schema = JSON.stringify(UpminsterSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-south-woodham-ferrers/") {
    schema = JSON.stringify(South_Woodham_FerrersSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-romford/") {
    schema = JSON.stringify(RomfordSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-ongar/") {
    schema = JSON.stringify(OngarSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-loughton/") {
    schema = JSON.stringify(LoughtonSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-hornchurch/") {
    schema = JSON.stringify(HornchurchSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-gravesend/") {
    schema = JSON.stringify(GravesendSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-dagenham/") {
    schema = JSON.stringify(DagenhamSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-brentwood/") {
    schema = JSON.stringify(BrentwoodSchema);
  } else if (location && location.pathname == "/branches/estate-agents-in-basildon/") {
    schema = JSON.stringify(BasildonSchema);
  } else {
    schema = JSON.stringify(LocalBusinessSchema);
  }

  return (
    <>
      <Helmet>
        <script type="application/ld+json">{schema}</script>
      </Helmet>
    </>
  );
}
export default OfficeSchema